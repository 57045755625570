.bgBackgroundColor {
    background-color: rgba(19, 19, 19, 0.5);
    z-index: 10;
}

.leadsCard {
    width: 500px;
    height: 250px;
    background-color: white;
    border-radius: 20px;
}

.warmLeads {
    border: 1px solid #FFFBE6;
    background-color: #FFFBE6;
    border-radius: 20px;
    color: #F8CE00;
    padding: 10px 15px;
}

.hotLeads {
    border: 1px solid #FFEBEB;
    background-color: #FFEBEB;
    border-radius: 20px;
    color: #FF2E2E;
    padding: 10px 15px;
}

.totalLeads {
    border: 1px solid rgba(124, 176, 231, 0.5);
    background-color: rgb(124, 176, 231, 0.5);
    border-radius: 20px;
    color: rgb(85,142,204);
    padding: 10px 15px;
}

.leadsCard p {
    font-size: 16px;
}

.cursor-pointer {
    cursor: pointer;
}

.topScroll {
    transform: translateY(-120px);
}

.borderStyle {
    width: 1px;
    height: 100px;
    background-color: #E8EEF0;
}
