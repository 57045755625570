/*! -----------------------------------------------------------------------------------

    Template Name: godo Admin
    Template URI: http://admin.pixelstrap.com/godo/theme
    Description: This is Admin theme
    Author: Pixelstrap
    Author URI: https://themeforest.net/user/pixelstrap

-----------------------------------------------------------------------------------


 */

// *** utils ***


@import "utils/variables";

:root {
    --theme-deafult: #194995;
    --theme-secondary:#21A5DE;
    --color-1: 255, 165, 0;
    --color-2: 185, 224, 51;
    --color-3: 48, 222, 110;
    --color-4: 33, 162, 220;
    --color-5: 252, 89, 89;
    --secodary: #787878;
}

// *** base ***
@import "base/reset";
@import "base/typography";


//  *** components ***
@import "components/according";
@import "components/alert";
@import "components/avatars";
@import "components/badge";
@import "components/bookmark";
@import "components/breadcrumb";
@import "components/builders";

@import "components/buttons";

@import "components/card";
@import "components/color";
@import "components/datepicker";
@import "components/dropdown";
@import "components/datatable";
// @import "components/form-builder";
@import "components/form-input";
@import "components/form-wizard";
@import "components/forms";
// @import "components/form_builder-2";
@import "components/icons";
@import "components/list";
@import "components/loader";
@import "components/popover";
@import "components/print";
@import "components/radio";
@import "components/ribbon";
@import "components/switch";
@import "components/tab";
@import "components/table";
@import "components/touchspin";
@import "components/tour";
@import "components/typeahead-search";
@import "components/react-plugin";
@import "components/slick.scss";
@import "components/slick-theme.scss";
@import "components/scroll.scss";
@import "components/timepicker";


//	*** pages ***

@import "pages/blog";
@import "pages/modalForm";
@import "pages/bookmark-app";
@import "pages/cart";
@import "pages/chart";
@import "pages/chat";
@import "pages/checkout";
@import "pages/comingsoon";
@import "pages/contacts";
@import "pages/calendar";
@import "pages/dashboard_2";
@import "pages/dashboard_default";
@import "pages/ecommerce";
@import "pages/email-application";
@import "pages/errorpage";
@import "pages/faq";
@import "pages/file";
@import "pages/gallery";
@import "pages/job-search";
@import "pages/kanban";
@import "pages/knowledgebase";
@import "pages/language";
@import "pages/learning";
@import "pages/login";
@import "pages/megaoption";
@import "pages/order-history";
@import "pages/page";
@import "pages/pricing";
@import "pages/progress";
@import "pages/projectlist";
@import "pages/social-app";
@import "pages/task";
@import "pages/timeline-v";
@import "pages/timeliny";
@import "pages/user-profile";
@import "pages/wishlist";


// *** layout *** 

@import "layout/footer";
@import "layout/grid";
@import "layout/header";
@import "layout/search";
@import "layout/sidebar";
@import "layout/rtl";
@import "layout/box-layout";

/* ---------------------
	*** themes ***
-----------------------*/
@import "themes/dark";
@import "themes/dark-sidebar";
@import "themes/theme-customizer";
@import "themes/responsive";

.card {
    box-shadow: 0 0 20px rgba(122, 132, 168, 0.35) !important;
}

.card .card{
    box-shadow: none !important;
}
.table{
    --bs-table-bg: transparent !important;
}
.high-priority {
    background: red !important;
    color: #fff;
    padding: 5px 15px;
    border-radius: 50px;
}
.normal-priority {
    background: orange !important;
    color: #fff;
    padding: 5px 15px;
    border-radius: 50px;
}
.low-priority {
    background: green !important;
    color: #fff;
    padding: 5px 15px;
    border-radius: 50px;
}
.normal-priority, .high-priority, .low-priority {
    width: 90px !important;
    display: block;
    text-align: center;
}
.fs_20{
    font-size: 20px;
}
.tableBody tr{
    vertical-align: middle;
}
body.dark-only .addl-class__control,body.dark-only .css-13cymwt-control{
    background-color: transparent;
}
//374558
body.dark-only .switch-outline .switch input:checked + .switch-state::before{
    background-color: #fff !important;
}
body.dark-only .card-header,body.dark-only h4,body.dark-only th,body.dark-only td,body.dark-only .form-control::placeholder,body.dark-only .addl-class__single-value,body.dark-only div{
    color: rgba(255, 255, 255, 0.7);
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links {
    height: calc(100vh - 200px);
  }
  .react-datepicker__time-container,.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box{
    width: 70px;
  }
  .react-time-picker__wrapper{
    border: none !important;
  }
  .form-check-input {
    position: relative !important;
    margin-left: 0px !important;
  }
  .fw_600{
    font-weight: 600;
  }
  hr{
    border: 1px solid #000 !important;
  }
  .search-fulls{
    border: 1px solid #ced4da;
    border-radius: 5px;
  }
  input#demo-input{
    width: 100% !important;
  }
  /*.form-label, .form-control,.addl-class__single-value,input,.css-1dimb5e-singleValue,td {
    font-weight: 300 !important;
    font-size: 14px !important;
  }*/
  .chart_data_right.col .card {
    max-height: 780px;
    overflow: scroll;
  }
  #global-loader1 {
    position: fixed;
    top: 50%; /* Center the loader vertically */
    left: 50%; /* Center the loader horizontally */
    transform: translate(-50%, -50%); /* Center the loader perfectly */
    z-index: 99;
    width: 100%;
    height: 100%; /* Make the loader cover the entire viewport */
    background: #2752a073; /* Semi-transparent background */
  }
  
  #global-loader1 img {
    position: absolute; /* Position the loader image absolutely */
    top: 50%; /* Center the image vertically */
    left: 50%; /* Center the image horizontally */
    width: 100px;
    transform: translate(-50%, -50%); /* Center the image perfectly */
    border-radius: 15px;
  }
  .new-box{
    padding: 3px 5px;
    background-color: var(--theme-deafult);
    font-size: 10px;
    color: #fff !important;
    margin-left: 10px;
  }
  .IB-icon circle{
    fill: red;
  }
  .Client-icon circle{
    fill: #28a745;
  }
  .modal-dialog.ModalAddTicket {
    max-width: 1000px;
  }
  .color_1{
    background-color: rgba(var(--color-1), 0.5) !important;
  }
  .color_2{
    background-color: rgba(var(--color-2), 0.5) !important;
  }
  .color_3{
    background-color: rgba(var(--color-3), 0.5) !important;
  }
  .color_4{
    background-color: rgba(var(--color-4), 0.5) !important;
  }
  .color_5{
    background-color: rgba(var(--color-5), 0.5) !important;
  }
  .color_5 .card-headers{
    border-bottom: 1px solid #FC5959 !important;
  }
  .color_4 .card-headers{
    border-bottom: 1px solid #21A2DC !important;
  }
  .color_3 .card-headers{
    border-bottom: 1px solid #30DE6E !important;
  }
  .color_2 .card-headers{
    border-bottom: 1px solid #B9E033 !important;
  }
  .color_1 .card-headers{
    border-bottom: 1px solid #FFA500 !important;
  }
  .saleName{
    color: #21A2DC !important;
  }
  .table-striped tbody tr:nth-of-type(2n+1) *{
    background-color: #F6F8FE;
    --bs-table-bg-type: unset;
  }
  .p-3.card-headers{
    padding-left: 2rem !important;
  }
  .p-3.card-header {
    font-size: 17px;
    font-weight: 500;
  }
  .page-body {
    background: #F4F6FA;
  }
  .color_4, .color_5, .color_1, .color_2, .color_3{
    box-shadow: none;
  }
  .card .card-body{
    padding: 30px;
  }
  .gray{
    color: var(--secodary);
  }
  .f_24{
    font-size: 24px;
  }
  .f_20{
    font-size: 20px;
  }
  .semibold{
    font-weight: 700 !important;
  }
  .medium{
    font-weight: 500 !important;
  }
  .justify-between {
    justify-content: space-between;
  }
  @media (min-width: 767px){
    .flex{
      display: flex;
    }
    .gap_20{
      gap: 20px;
    }
  }

  .agentId___control {
    border: 2px solid green !important;
  }
  .retention___control {
    border: 2px solid red !important;
  }

  .pointer{
    cursor: pointer;
  }