/**=====================
      90. React-Plugin CSS Start
==========================**/
.simple-list {
  .list-group-item {
    padding: 0;
    border: none;
  }
}

// slick
.slick-page {
  .slick-slider {
    margin: -8px 0 20px;
  }
}

.slick-slider {
  margin: -7px 0 0;

  .item {
    &:focus {
      outline: none;
    }
  }

  .slick-dots {
    li {
      button {
        &:before {
          font-size: 12px;
          color: var(--theme-deafult);
        }
      }

      &.slick-active {
        button {
          &:before {
            color: var(--theme-deafult);
          }
        }
      }
    }
  }
}

// range slider
.rangeslider-horizontal,
.rangeslider-vertical {
  .rangeslider__fill {
    background-color: var(--theme-deafult) !important;
  }

  .rangeslider__handle {
    &:focus {
      outline: none;
    }
  }
}

.slider-vertical,
.vertical-height {
  .value {
    text-align: center;
  }
}

.rangeslider-vertical {
  .rangeslider__labels {
    margin: 0 0 0 32px !important;
  }
}

.input-range__slider {
  background: var(--theme-deafult);
  border: 1px solid var(--theme-deafult);
}

.input-range__track--active {
  background: var(--theme-deafult);
}

.input-range {
  margin-bottom: 34px;
}

// dropzone
.dzu-dropzone {
  border: 2px dashed var(--theme-deafult) !important;
  background: rgba($primary-color, 0.2);
  overflow: auto !important;

  .dz-message {
    .note {
      background-color: $transparent-color;
    }
  }
}

.dzu-inputLabel {
  color: var(--theme-deafult) !important;
  text-align: center;
  margin-bottom: 0;
}

.dzu-previewContainer {
  padding: 30px !important;
}

.dzu-inputLabelWithFiles {
  margin-left: 30px !important;
  background-color: var(--theme-deafult) !important;
  color: $white  !important;
}

.dzu-submitButton {
  background-color: var(--theme-deafult) !important;

  &:disabled {
    background-color: #e6e6e6 !important;
  }
}

// Image Cropper
.input-cropper {
  margin-bottom: 15px;
  border: 1px solid #ced4da;
  padding: 10px;
  border-radius: 4px;
  width: auto;

  input {
    width: -webkit-fill-available;
  }
}

.crop-portion {
  margin-left: 0;
  margin-top: 30px;
  vertical-align: baseline;
}

.cropper-view-box {
  outline-color: var(--theme-deafult);
  outline: 1px solid var(--theme-deafult);
}

.cropper-point {
  background-color: var(--theme-deafult);
}

// sticky
.note {
  background-color: rgba($primary-color, 0.3);

  &:nth-child(2n) {
    background: rgba($secondary-color, 0.3);
  }
}

.react-stickies-wrapper {
  .close {

    &:before,
    &:after {
      background: $transparent-color  !important;
    }
  }

  .react-grid-layout {
    .react-grid-item {
      &:nth-child(1n) {
        .note {
          background: lighten($primary-color, 35%) !important;
        }
      }

      &:nth-child(2n) {
        .note {
          background: lighten($secondary-color, 35%) !important;
        }
      }

      &:nth-child(3n) {
        .note {
          background: lighten($success-color, 50%) !important;
        }
      }

      &:nth-child(4n) {
        .note {
          background: lighten($info-color, 30%) !important;
        }
      }

      &:nth-child(5n) {
        .note {
          background: lighten($warning-color, 35%) !important;
        }
      }

      &:nth-child(6n) {
        .note {
          background: lighten($danger-color, 25%) !important;
        }
      }
    }
  }
}

// sweetalert
.lead {
  margin-bottom: 5px;
}

.swal2-container {
  .swal2-actions {
    .swal2-styled {
      font-size: 14px;
    }
  }
}

.swal2-styled {
  &.swal2-confirm {
    background-color: var(--theme-deafult) !important;

    &:focus {
      box-shadow: 0 0 0 1px $white, 0 0 0 3px rgba($primary-color, 0.4);
    }
  }
}

.swal2-title {
  font-size: 20px !important;
}

.swal2-progress-steps {

  .swal2-progress-step,
  .swal2-progress-step-line {
    background: var(--theme-deafult) !important;
  }

  .swal2-progress-step {
    &.swal2-active-progress-step {
      background: var(--theme-deafult) !important;

      ~.swal2-progress-step,
      ~.swal2-progress-step-line {
        background: rgba($primary-color, 0.2) !important;
      }
    }
  }
}

// datetime picker
.bootstrap-datetimepicker-widget {
  tr {

    th,
    td {

      &:active,
      &.active {
        background-color: var(--theme-deafult);
      }

      span {

        &:active,
        &.active {
          background-color: var(--theme-deafult);
        }
      }
    }
  }
}

// upload
.fileContainer {
  padding: 0 !important;
  margin: 0 !important;
  box-shadow: none !important;

  .files-gallery {
    display: flex;
    justify-content: center;

    .files-gallery-item {
      width: 40%;
      text-align: center;
      align-items: center;
    }
  }

  .uploadPictureContainer {
    margin: 1% !important;
    padding: 10px;
  }

  .chooseFileButton {
    margin-top: 0 !important;
    padding: 6px 23px;
    background: $secondary-color;
    border-radius: 30px;
    color: $white;
    font-weight: 300;
    font-size: 14px;
    margin: 10px 0;
    transition: all 0.2s ease-in;
    cursor: pointer;
    outline: none;
    border: none;
  }
}

// daterange picker
.daterangepicker {

  td,
  th {
    &.available {
      &:hover {
        background-color: var(--theme-deafult);
      }
    }
  }
}

// to-do
.todo {
  .action-box {
    &.large {
      .icon {

        .icon-trash,
        .icon-check {
          color: var(--theme-deafult);
        }
      }
    }
  }

  .todo-list-wrapper {
    #todo-list {
      li {
        &.completed {
          .task-container {
            .task-label {
              color: var(--theme-deafult);
            }
          }
        }
      }
    }
  }

  @-webkit-keyframes taskHighlighter {
    0% {
      background: rgba($primary-color, 0.5);
    }

    100% {
      background: $white;
    }
  }

  @keyframes taskHighlighter {
    0% {
      background: rgba($primary-color, 0.5);
    }

    100% {
      background: $white;
    }
  }
}

// clock
.react-clock__face {
  border: 1px solid $white  !important;
}

.react-clock__mark__body,
.react-clock__hand__body {
  background-color: $white  !important;
}

// scrollable page
.scrollarea {
  max-width: 800px;
  max-height: 300px;

  .scrollbar-container {
    z-index: 7 !important;
  }
}

//form wizard
ol {
  &.progtrckr {
    list-style-type: none;
    padding: 0;

    li {
      display: inline-block;
      text-align: center;
      line-height: 4.5rem;
      cursor: pointer;

      span {
        padding: 0 1.5rem;
      }

      &.progtrckr-doing {
        color: black;
        border-bottom: 4px solid #cccccc;

        &:before {
          content: "\2022";
          color: white;
          background-color: #cccccc;
          width: 1.2em;
          line-height: 1.2em;
          border-radius: 1.2em;
        }

        &:hover {
          &:before {
            color: #ff4500;
          }
        }
      }

      &.progtrckr-done {
        color: black;
        border-bottom: 4px solid #5cb85c;

        &:before {
          content: "\2713";
          color: white;
          background-color: #5cb85c;
          width: 1.2em;
          line-height: 1.2em;
          border-radius: 1.2em;
        }

        &:hover {
          &:before {
            color: #333;
          }
        }
      }

      &:after {
        content: "\00a0\00a0";
      }

      &:before {
        position: relative;
        bottom: -3.7rem;
        float: left;
        left: 50%;
      }

      &.progtrckr-todo {
        &:before {
          content: "\039F";
          color: silver;
          background-color: $transparent-color;
          width: 1.2em;
          line-height: 1.4em;
        }

        &:hover {
          &:before {
            color: #ff4500;
          }
        }
      }
    }
  }
}

// calender
.rbc-calendar {
  height: 1000px !important;
}

#external-events {
  position: absolute;
  z-index: 2;
  padding: 0 10px;
  border: 1px solid #ccc;
  background: #eee;
  overflow-y: scroll;

  p {
    strong {
      font-size: 16px;
    }
  }
}

#external-events .fc-event {
  margin: 1em 0;
  cursor: move;
}

//   base-typeahead
.rbt-token {
  color: var(--theme-deafult) !important;
  background-color: rgba($primary-color, 0.1) !important;

  .rbt-token-remove-button {
    top: 1px !important;
  }
}

.btn-toolbar {
  margin-bottom: -8px !important;
}

.rbt-aux {
  .rbt-close {
    margin-top: 0;
  }
}

// time-picker
.react_times_button {
  .wrapper {
    display: block;
  }
}

.time_picker_wrapper2 {
  display: flex;

  .time_picker_container {
    width: 44%;
  }

  .gap {
    padding: 0 5px;
  }
}

// toastr
.Toastify__close-button {
  font-weight: 500;

  svg {
    stroke: $white  !important;
    fill: $white  !important;
  }
}

.Toastify__toast--success {
  background: lighten($success-color, 15%) !important;
  color: $white  !important;
  font-weight: 500;

  svg {
    fill: transparent !important;
    stroke: $white  !important;
  }

  .Toastify__progress-bar {
    background: lighten($success-color, 35%) !important;
  }
}

.Toastify__toast--info {
  background: lighten($info-color, 15%) !important;
  color: $white  !important;

  svg {
    fill: transparent !important;
    stroke: $white  !important;
  }

  .Toastify__progress-bar {
    background: lighten($info-color, 35%) !important;
  }
}

.Toastify__toast--warning {
  background: lighten($warning-color, 5%) !important;
  color: $white  !important;

  svg {
    fill: transparent !important;
    stroke: $white  !important;
  }

  .Toastify__progress-bar {
    background: lighten($warning-color, 15%) !important;
  }
}

.Toastify__toast--error {
  background: lighten($danger-color, 10%) !important;
  color: $white  !important;
  font-weight: 500;

  svg {
    fill: transparent !important;
    stroke: $white  !important;
  }

  .Toastify__progress-bar {
    background: lighten($danger-color, 20%) !important;
  }
}

// draggable card
.draggable-card {
  >div {
    >div {
      width: 100%;
      display: flex;

      .react-kanban-column {
        width: 100%;
      }

      .react-kanban-card {
        min-width: 100%;
        max-width: 100%;
      }
    }
  }
}

.ui-sortable {
  .react-kanban-board {
    div[data-rbd-droppable-id="board-droppable"] {
      width: 100%;
      display: flex;
    }

    .react-kanban-column {
      width: 100%;
    }

    .react-kanban-card {
      max-width: 100%;
    }
  }
}

.jkanban-container {
  .custom-board {
    .kanban-drag {
      .react-kanban-column {
        &:nth-child(2) {
          div[data-rbd-drag-handle-context-id="2"] {
            background-color: $warning-color;

            ~div {
              background-color: rgba($warning-color, 0.3);
            }
          }
        }

        &:nth-child(3) {
          div[data-rbd-drag-handle-context-id="2"] {
            background-color: $success-color;

            ~div {
              background-color: rgba($success-color, 0.3);
            }
          }
        }
      }

      div[data-rbd-drag-handle-context-id="2"] {
        background-color: $info-color;
        color: $white;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        ~div {
          background-color: rgba($info-color, 0.3);
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        }

        span {
          font-weight: 500;
        }

        span.badge {
          font-weight: 400;
        }
      }
    }
  }

  .kanban-container {
    display: flex;

    .kanban-board {
      width: 100%;
    }

    .kanban-item {
      .customers {
        ul.simple-list {
          li {
            background-color: $transparent-color;

            +li {
              img {
                margin: 0;
              }
            }
          }
        }
      }

      &:focus {
        outline: none;
      }

      .kanban-box {
        border: 1px solid $light-semi-gray;
        background-color: $white  !important;
        color: $theme-body-font-color;

        &:focus {
          outline: none;
        }
      }
    }

    .kanban-drag {
      width: 100%;

      .react-kanban-column-header {
        font-weight: 500;

        &:focus {
          outline: none;
        }

        >div {
          &:focus {
            outline: none;
          }
        }
      }

      >div {
        padding: 0;

        >div {
          display: flex;
          width: 100%;
          margin-bottom: -25px;

          .react-kanban-column {
            width: 98%;
            background-color: $body-color;
            border-radius: 8px;
            padding: 0;
            margin-bottom: 30px;

            >div {
              width: 100%;
              padding: 20px;
              font-size: 16px;
              border-bottom: 1px solid rgba($primary-color, 0.1);

              p {
                font-weight: 400;
              }

              >div {
                padding-bottom: 0;
              }
            }

            .react-kanban-board {
              >div {
                width: 100%;
              }

              +.react-kanban-board {
                margin-top: 20px;
              }
            }
          }
        }
      }
    }
  }

  .react-kanban-column {
    background-color: $body-color;

    >div {
      >div {
        >div {
          display: block !important;
        }
      }
    }

    div[tabindex="0"] {
      +div[tabindex="0"] {
        margin-top: 20px;
      }
    }
  }
}

//DragNDrop
.ui-sortable {
  .container {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: var(--theme-deafult);

    div {
      padding: 10px;
      background-color: darken($primary-color, 1%);
      transition: opacity 0.4s ease-in-out;
      color: $white;
    }

    div {
      cursor: move;
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.ex-moved {
      background-color: #e74c3c;
    }

    &.ex-over {
      background-color: var(--theme-deafult);
    }

    &:nth-child(odd) {
      background-color: var(--theme-deafult);
    }
  }
}

.gh-fork {
  position: fixed;
  top: 0;
  right: 0;
  border: 0;
}

.gu-mirror {
  padding: 10px;
  background-color: darken($primary-color, 5%);
  transition: opacity 0.4s ease-in-out;
  color: $white;
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
}

.gu-hide {
  display: none !important;
}

.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

.wrapper {
  display: table;
}

.gu-mirror {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.handle {
  padding: 0 5px;
  margin-right: 5px;
  background-color: darken($primary-color, 15%);
  cursor: move;
}

// timeline
.vertical-timeline {
  &::before {
    background: $light-color;
  }

  .vertical-timeline-element {
    p {
      font-size: 13px !important;
    }

    .vertical-timeline-element-icon {
      color: $white;
    }

    &:first-child,
    &:nth-child(7) {
      .vertical-timeline-element-icon {
        background-color: var(--theme-deafult);
      }
    }

    &:nth-child(2) {
      .vertical-timeline-element-icon {
        background-color: $secondary-color;
      }
    }

    &:nth-child(3),
    &:last-child {
      .vertical-timeline-element-icon {
        background-color: $success-color;
      }
    }

    &:nth-child(4) {
      .vertical-timeline-element-icon {
        background-color: $info-color;
      }
    }

    &:nth-child(5) {
      .vertical-timeline-element-icon {
        background-color: $warning-color;
      }
    }

    &:nth-child(6) {
      .vertical-timeline-element-icon {
        background-color: $danger-color;
      }
    }
  }
}

.vertical-timeline-element-content {
  box-shadow: 1px 1px 2px 1px rgba($black, 0.08);
  background-color: $light;

  audio {
    width: 100%;
    margin-top: 15px;
  }

  .vertical-timeline-element-date {
    font-size: 14px !important;
  }

  .vertical-timeline-element-content-arrow {
    border-right: 7px solid $light-color;
  }
}

.vertical-timeline--two-columns {
  .vertical-timeline-element-content {
    .vertical-timeline-element-date {
      top: 10px !important;
    }
  }
}

.vertical-timeline--one-column {
  &:before {
    left: 28px !important;
  }

  .vertical-timeline-element-icon {
    width: 60px;
    height: 60px;
  }

  .vertical-timeline-element-content {
    margin-left: 90px;

    img {
      width: 500px;
    }

    .vertical-timeline-element-date {
      float: none;
      display: block;
    }
  }
}

.rdt_Table {
  .rdt_TableHead {
    .rdt_TableHeadRow {
      .rdt_TableCol {
        &:nth-child(4) {
          .rdt_TableCol_Sortable {
            justify-content: center;

            span {
              display: none;
            }
          }
        }

        .rdt_TableCol_Sortable {
          justify-content: flex-start;

          >div {
            font-weight: 500 !important;
          }
        }
      }
    }
  }

  .rdt_TableBody {
    .rdt_TableRow {
      .rdt_TableCell {
        padding: 12px;

        &:nth-child(4) {
          justify-content: center !important;
        }

        +.rdt_TableCell {
          justify-content: flex-start;
        }
      }
    }
  }
}

// leafleft map
.leaflet-top,
.leaflet-bottom {
  z-index: 7 !important;
}

.leaflet-pane {
  z-index: 1 !important;
}

// vector map
.map-block {
  svg {
    g {
      transform-origin: 0 0;
    }
  }
}

#gmap-simple {
  svg {
    path {
      fill: var(--theme-deafult) !important;
    }
  }
}

#usa {
  svg {
    path {
      fill: $secondary-color  !important;
    }
  }
}

#canada {
  svg {
    path {
      fill: $success-color  !important;
    }
  }
}

#asia {
  svg {
    path {
      fill: $warning-color  !important;
    }
  }
}

#europe {
  svg {
    path {
      fill: $danger-color  !important;
    }
  }
}

#america {
  svg {
    path {
      fill: $dark-color  !important;
    }
  }
}

.jvectormap-zoomin,
.jvectormap-zoomout {
  width: 20px;
  height: 20px;
}

.jvectormap-zoomin {
  bottom: 65px;
}

.jvectormap-zoomout {
  bottom: 30px;
}

.jvectormap-zoomin,
.jvectormap-zoomout,
.jvectormap-goback {
  position: absolute;
  border-radius: 3px;
  background: #292929;
  padding: 5px;
  color: #fff;
  cursor: pointer;
  text-align: center;
  box-sizing: content-box;
}

// rating
.rating {
  align-items: center;

  span {
    .fa {
      color: var(--theme-deafult);
    }

    span {
      span {
        color: var(--theme-deafult);
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .jkanban-container {
    .kanban-container {
      .kanban-drag {
        padding: 0;

        >div {
          width: 100% !important;

          >div {
            div[data-rbd-drag-handle-context-id="2"] {
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}

/**=====================
      90. React-Plugin CSS Ends
==========================**/